:root {
  --bkg-clr: rgb(15, 15, 15);
  --card-bkg-clr: rgb(21, 21, 21);
  --card-bkg-clr-trans: rgba(21, 21, 21, .7);
  --txt-clr: rgb(208, 208, 208);
  --trim-clr: rgb(21, 21, 21);
  --btn-clr: rgb(100, 100, 100);
  --btn-hover-clr: rgb(158, 158, 158);
  --txt-hover-clr: rgb(0, 0, 0);
  --alt-border-clr: rgb(24, 24, 24);
  --alt-shadow-clr: rgb(119, 82, 183);
}


body {
  background-color: var(--bkg-clr);
  font-family: "Rubik", sans-serif;
  color: var(--txt-clr);
  position: relative;
  min-height: 100vh;
}

.navbar {
  padding: 2rem;
  background-color: var(--trim-clr);
}
.navbar-brand {
  font-size: 16px;
  color: white;
}
@media only screen and (max-width: 600px) {
  .navbar-brand {
    font-size: 16px;
    color: white;
  }
}

.lang-btn {
  padding: .5rem;
  width: fit-content;
  margin-top: .5rem;
  background-color: var(--trim-clr);
  color: white;
  border: none;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.lang-btn:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.lang-img {
  height: 20px;
}

.intro {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
}

.title {
  margin: 4rem;
  text-align: center;
  color: var(--txt-clr);
}

.card {
  height: 100%;
  background-color: var(--bkg-clr);
  color: var(--txt-clr);
  border: 1px solid rgba(120, 120, 120, 0);
}

.card h5 {
  font-size: 24px;
}

.card p {
  color: var(--txt-clr);
}

.item-container {
  width: 100%;
  margin: 2em;
  padding: 4rem;
  border-bottom: 1px solid rgba(225, 225, 225, .3);
}

.flex-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-sm {
  background-color: var(--btn-clr);
  color: white;
  margin: .25rem .25rem .25rem 0;
  text-decoration: none;
}

.btn-sm:hover {
  transition: 500ms;
  background-color: var(--btn-hover-clr);
  color: var(--txt-hover-clr);
}

.img-height {
  height: 100%;
}

.prof-img {
  width: 100%;
  border-radius: 50%;
}

.name_title {
  color: #9D00FF;
}

.role_title {
  margin: 1em 0 2em 0;
  color: #9D00FF;
}

.email-link {
  font-weight: 600;
}
.card-header {
  background-color: var(--bkg-clr);
  color: white;
  border-bottom: 1px solid var(--txt-clr)
}

.email {
  font-weight: 700;
}

.main {
  padding-bottom: 5rem;
  height: 100%;
}

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blog-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.spinner {
  margin-top: 5rem; 
  text-align: center;
}

.spinner-border {
  font-size: 24px;
  color: var(--txt-clr);
}

.summary-container {
  text-align: center;
}

.summary-box {
  background-color: #2c2c2c;
  padding: 1em;
  border-radius: 20px;
  text-align: left;
}

.btn-summary {
  margin: 1em 0;
  padding: .5em;
  background-color: rgb(54, 54, 54);
  color: whitesmoke;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.btn-summary:hover {
  transition: 1s;
  background-color: rgb(105, 106, 106);
  color: #ffffff;
}